// pages/index.js
import { useUser } from "@auth0/nextjs-auth0/client";
import Link from "next/link";
import PublicPageMetaHead from "@/components/seo/PublicPageMetaHead";

export default function Home() {
	const { user, isLoading } = useUser();
	const namespace = process.env.NEXT_PUBLIC_AUTH0_NAMESPACE;
	const isSuperUser = user && user[namespace]?.includes("superuser");

	if (isLoading) return null;

	return (
		<>
			<PublicPageMetaHead
				title="Rent IQ App"
				description="Rent IQ App"
				url="https://app.rentiq.com"
				image="https://cdn.rentiq.com/rent-iq/rentiq-logo.png"
			/>
			<main className="p-8">
				<h1 className="text-2xl font-bold mb-6">Home</h1>
				{user ? (
					<div>
						<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
							{isSuperUser && (
								<div className="bg-white shadow-lg rounded-lg p-6">
									<h2 className="text-lg font-semibold">Admin Panel</h2>
									<p>Access the administrative panel.</p>
									<Link
										href="/admin"
										className="inline-block mt-4 px-6 py-2 bg-black text-white rounded hover:bg-gray-800 transition duration-300"
									>
										Go to Admin
									</Link>
								</div>
							)}
							{!isSuperUser && (
								<div className="bg-white shadow-lg rounded-lg p-6">
									<h2 className="text-lg font-semibold">Properties</h2>
									<p>Manage properties and their units.</p>
									<Link
										href="/properties"
										className="inline-block mt-4 px-6 py-2 bg-black text-white rounded hover:bg-gray-800 transition duration-300"
									>
										Go to Properties
									</Link>
								</div>
							)}
						</div>
					</div>
				) : (
					<div>
						<p>You are not logged in.</p>
						{/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
						<a
							href="/api/auth/login"
							className="inline-block mt-4 px-6 py-2 bg-black text-white rounded hover:bg-gray-800 transition duration-300"
						>
							Log in
						</a>
					</div>
				)}
			</main>
		</>
	);
}
